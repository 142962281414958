@font-face {
	font-family: 'HelveticaNeueCyr';
	font-style: normal;
	font-weight: normal;
	src: url('./HelveticaNeueCyr.woff2') format('woff2'),
		url('./HelveticaNeueCyr.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	font-style: italic;
	font-weight: normal;
	src: url('./HelveticaNeueCyrItalic.woff2') format('woff2'),
		url('./HelveticaNeueCyrItalic.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	font-style: normal;
	font-weight: 300;
	src: url('./HelveticaNeueCyr-Light.woff2') format('woff2'),
		url('./HelveticaNeueCyr-Light.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	font-style: italic;
	font-weight: 300;
	src: url('./HelveticaNeueCyr-LightItalic.woff2') format('woff2'),
		url('./HelveticaNeueCyr-LightItalic.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	font-style: normal;
	font-weight: 500;
	src: url('./HelveticaNeueCyr-Medium.woff2') format('woff2'),
		url('./HelveticaNeueCyr-Medium.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	font-style: italic;
	font-weight: 500;
	src: url('./HelveticaNeueCyr-MediumItalic.woff2') format('woff2'),
		url('./HelveticaNeueCyr-MediumItalic.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	font-style: normal;
	font-weight: bold;
	src: url('./HelveticaNeueCyr-Bold.woff2') format('woff2'),
		url('./HelveticaNeueCyr-Bold.woff') format('woff');
}

@font-face {
	font-family: 'HelveticaNeueCyr';
	font-style: italic;
	font-weight: bold;
	src: url('./HelveticaNeueCyr-BoldItalic.woff2') format('woff2'),
		url('./HelveticaNeueCyr-BoldItalic.woff') format('woff');
	font-display: swap;
}